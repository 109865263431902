.contact-wrapper {

    .contact {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .contact-ways {
            width: 100%;
            display: flex;
            height: 100%;
            gap: $gap;
            padding: $gap;

            .way {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                padding: $pad;
                transition: 0.2s;
                background: transparent;
                color: $white;
                font-family: Chillax-regular;
                box-shadow: 5px 5px 1px #1d1d20,
                    -5px -5px 1px #232326;

                @include for-mobile-layout {

                    &::after {
                        content: "" !important;
                    }

                }


                &:nth-child(1) {
                    &::after {
                        content: "/eilonv";
                    }
                }

                &:nth-child(2) {
                    &::after {
                        content: "/eilonvana";
                    }
                }

                &:nth-child(3) {
                    &::after {
                        content: "contact@eilonvana.dev";
                    }
                }

                &:nth-child(4) {
                    &::after {
                        content: "054-6630092";
                    }
                }

                &:nth-child(5) {
                    &::after {
                        content: "054-6630092";
                    }
                }

                &::after {
                    all: unset;
                    position: absolute;
                    bottom: 0;
                    opacity: 0;
                    transition: 0.2s;
                    color: $black;
                    font-weight: 700;
                    word-break: break-all;
                    text-align: center;
                }

                .way-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: $gap;

                    i {
                        font-size: 40px;
                    }

                    p {
                        color: $black;
                        font-weight: 700;
                        font-size: $small;

                        @include for-mobile-layout {
                            display: none;
                        }
                    }
                }


                &:hover {
                    background-color: $lavender;
                    cursor: pointer;

                    i {
                        color: $mustard;
                    }

                    &::after {
                        transition: opacity 1s 1s;
                        opacity: 1;
                    }
                }
            }
        }

        .label {
            display: flex;
            // width: 100%;
            // height: 100%;

            .color {
                background-color: $mustard;
                // width: 100%;
                // height: 100%;
                width: 180px;
            }
        }

        h2 {
            font-size: $h1;
            background-color: $lavender;
            color: $mustard;
            padding: 50px 300px;
            height: 100%;
            font-family: Chillax-Bold;
            font-size: $h1;
            text-align: center;

            @include for-wider-layout {
                padding: 50px;
                font-size: $h1-tablet;
                text-align: center;
            }

            @include for-mobile-layout {
                padding: $pad;
                font-size: $h1-mobile;
                text-align: center;
            }
        }

        form {
            width: 100%;
            padding: 0 $pad $pad $pad ;
            gap: $gap;

            @include for-mobile-layout {
                flex-direction: column;
                padding: 0 0 10px 0;
            }

            .inputs {
                width: 100%;
                gap: $gap;

                * {
                    background-color: $lavender;
                    color: $black;
                    font-family: Chillax-semibold;

                    &::placeholder {
                        color: $black;
                        font-family: Chillax-semibold;
                        font-size: $small;
                    }
                }

                input {
                    height: 20%;
                }

            }

            button {
                width: 20%;
                cursor: pointer;
                text-align: center;
                font-family: Chillax-semibold;
                font-size: $small;
                background-color: $mustard;
                border-color: $mustard;
                color: $black;

                @include for-mobile-layout {
                    all: unset;
                    width: 100%;
                    cursor: pointer;
                    text-align: center;
                    font-family: Chillax-semibold;
                    font-size: $small;
                    background-color: $mustard;
                    border-color: $mustard;
                    color: $black;
                    padding: $pad 0;
                }
            }


        }
    }
}