.home {


    width: auto;
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    -ms-overflow-style: none;
    scroll-behavior: smooth;

    @include for-mobile-layout {
        transform: none;
        height: 100%;
        transform-origin: none;
        overflow-y: visible;
        overflow-x: visible;
        position: static;

    }


}

.wrapper {
    width: 100vh;
    // min-width: 100%;
    display: flex;
    height: 100%;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;

    @include for-mobile-layout {
        transform: none;
        width: 100%;
        transform-origin: none;
        flex-direction: column;

    }
}

.section {
    @include for-mobile-layout {
        width: 100%;
        height: 100%;
    }

    width: 100vw;
    height: 100vh;
    // min-width: 100%;
}