.projects {
    position: relative;
    display: flex;
    margin-left: $gap-large;
    min-width: max-content;

    @include for-mobile-layout {
        min-width: 0;
        margin-left: 0;
        flex-direction: column;
    }

    h2 {
        writing-mode: vertical-rl;
        position: static;
        background-color: $mustard;
        color: $black;
        height: 100%;
        padding: 20px 70px 0 70px;
        font-family: Chillax-Bold;
        font-size: $h1;
        text-align: center;

        @include for-mobile-layout {
            writing-mode: horizontal-tb;
            font-size: $h1-mobile;
            padding: $pad;
        }
    }

    .grid {
        display: flex;

        @include for-mobile-layout {
            flex-direction: column;
        }

        .project-wrapper {
            width: 100%;
            height: 50%;
            padding: $pad;
            aspect-ratio: 1/1;
            border: 2px $mustard solid;

            .project {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                transition: 0.3s;

                &:hover {
                    .info p {
                        opacity: 1;
                        display: block;
                    }
                }

                .info {
                    // background-color: rgba(0, 0, 0, 0.603);
                    position: relative;

                    h3 {
                        width: 100%;
                        background-color: $mustard;
                        color: $black;
                        padding: $pad;

                    }

                    p {
                        transition: 0.3s;
                        font-size: $small;
                        padding: $pad;
                        opacity: 0;
                        background-color: $black-trans;
                    }
                }

                &.first {
                    background-image: url('../../images/gaya.png');
                    background-size: 150% 100%;
                }

                &.second {
                    background-image: url('../../images/shay.png');
                    background-size: 150% 100%;
                    background-position: 70%;
                }

                &.third {
                    background-image: url('../../images/anime.png');
                    background-size: 170% 100%;
                    background-position: 60%;
                }

                &.fourth {
                    background-image: url('../../images/texture.png');
                    background-size: cover;
                    background-position: center;
                }

                &.fifth {
                    background-image: url('../../images/color.png');
                    background-size: 140% 120%;
                    background-position: 0% -10%;
                }

                &.sixth {
                    background-image: url('../../images/chinese.png');
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}