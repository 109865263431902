.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: rgba(250, 235, 215, 0.261);
    min-width: 80vw;
    align-items: center;
    margin-left: $gap-large;

    @include for-mobile-layout {
        margin-left: 0;
    }

    .top-row {
        display: flex;
        gap: $gap-large;
        width: 100%;
        padding: $pad;

        @include for-wider-layout {
            flex-direction: column;
            gap: $gap;
        }

        @include for-mobile-layout {
            flex-direction: column;
            gap: $gap;
        }

        img {
            align-self: center;
            object-fit: cover;
            aspect-ratio: 1/1;
            max-width: 600px;
            max-height: 600px;
            width: 100%;
            border-radius: 50%;
            opacity: 0;
            animation: opacity-rotate 1.5s forwards cubic-bezier(0.455, 0.03, 0.515, 0.955);
            user-select: none;

            @keyframes opacity-rotate {

                20% {
                    rotate: -20deg;

                }

                60% {
                    opacity: 1;

                    rotate: 380deg;
                }

                80% {
                    rotate: 340deg;
                }

                85% {
                    rotate: 365deg;
                }

                90% {
                    rotate: 355deg;
                }

                100% {
                    rotate: 360deg;
                    opacity: 1;
                }
            }

            @include for-wider-layout {
                max-width: 500px;
                max-height: 500px;
                margin-top: $margin * 2;
            }

            @include for-mobile-layout {
                margin-top: 0;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            text-align: center;
            // text-wrap: nowrap;
            // width: 100%;
            height: 100%;
            justify-content: space-around;
            // width: min-content;
            // width: fit-content !important;
            padding: $pad;
            flex-wrap: wrap;

            :not(.flex, ul, li) {
                opacity: 0;
                transform: translateY(20px);
                animation: appear 0.5s 0.2s forwards ease-in-out;
            }

            div {
                width: 100%;
            }

            h1 {
                width: max-content;
                width: 100%;
                text-align: center;
                letter-spacing: $small ;
            }

            h2 {
                width: 100%;
                letter-spacing: $smaller ;
                color: $lavender;
                word-wrap: break-word;
            }

            p {
                width: 100%;
                text-wrap: wrap;
            }

            @include for-mobile-layout {
                padding: 0;
            }
        }

        .education {
            font-size: $small;
            margin: 10px 0;
            color: $lavender;
            font-family: Chillax-light;
            font-weight: 700;
        }
    }

    ul {
        width: fit-content;
        display: flex;
        gap: $gap;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: $margin;
        transition: 0.2s;

        @keyframes appear {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        :nth-child(1) {
            animation: appear 0.5s forwards ease-in-out;
        }

        :nth-child(2) {
            animation: appear 0.5s 0.1s forwards ease-in-out;
        }

        :nth-child(3) {
            animation: appear 0.5s 0.2s forwards ease-in-out;

        }

        :nth-child(4) {
            animation: appear 0.5s 0.3s forwards ease-in-out;

        }

        :nth-child(5) {
            animation: appear 0.5s 0.4s forwards ease-in-out;

        }

        :nth-child(6) {
            animation: appear 0.5s 0.5s forwards ease-in-out;

        }

        :nth-child(7) {
            animation: appear 0.5s 0.6s forwards ease-in-out;

        }

        :nth-child(8) {
            animation: appear 0.5s 0.7s forwards ease-in-out;

        }

        :nth-child(9) {
            animation: appear 0.5s 0.8s forwards ease-in-out;

        }

        li {
            border: 2px $lavender solid;
            padding: $btn-pad;
            border-radius: 20px;
            opacity: 0;
            transform: translateY(20px);
        }
    }

}