.main-layout {
    display: grid !important;
    grid-template-columns:
        minmax(32px, 1fr) minmax(auto, 1400px) minmax(32px, 1fr);

    >* {
        grid-column: 2;

        &.full {
            grid-column: 1 / -1;
        }
    }

    @include for-mobile-layout {
        @include layoutGTC($mobile-breakpoint);
    }

    @include for-tablet-layout {
        @include layoutGTC($tablet-breakpoint);
    }

    // @include for-wide-layout {
    //     @include layoutGTC($desktop-breakpoint);
    // }

    @include for-wider-layout {
        @include layoutGTC($wide-dekstop-breakpoint);
    }
}