// COLORS
$black: #202023;
$black-trans: rgba(32, 32, 35, 0.5);
$purple: #4f2892;
$lavender: #8c87d3;
$white: #e6e4f1;
$mustard: #ffe347;

// LAYOUT
$layoutPadding: 140px;
$pad: 10px;
$pad-large: 30px;
$btn-pad: 4px 8px;
$gap: 10px;
$gap-large: 50px;
$margin: 20px;

// BREAKPOINTS
$mobile-breakpoint: 460px;
$tablet-breakpoint: 800px;
$desktop-breakpoint: 960px;
$wide-dekstop-breakpoint: 1300px;

// VARS

// TYPOGRAPHY
$smaller: 14px;
$small: 18px;
$p: 30px;
$h1: 140px;
$h2: 64px;
$h3: 30px;
$h4: 22px;
$btn-text: 22px;
$in-radius: 12px;
$br: 12px;

$smaller-mobile: 12px;
$small-mobile: 16px;
$p-mobile: 24px;
$h1-mobile: 16vw;
$h2-mobile: 10vw;
$h3-mobile: 24px;
$h4-mobile: 18px;

$h1-tablet: 8vw;
$h2-tablet: 4vw;
$p-tablet: 20px;